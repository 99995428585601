import React from 'react';

const FaqItem = ({
    title,
    text
}) => {
    const [open, setOpen] = React.useState(false);

    return (
        <div className={`faq__content--item${open ? " open" : ""}`}>
            <div className="faq__content--item--title--inner" onClick={() => setOpen(prev => !prev)}>
                <p className="faq__content--item--title">
                    {title}
                </p>

                <img src="/assets/img/arrow-right-black.svg" alt="arrow" className="faq__content--item--title--arrow" />
            </div>

            <p className="faq__content--item--text">
                {text}
            </p>
        </div>
    )
}

export default FaqItem;