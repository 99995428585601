import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './index.css';

import {NAME} from '../../const/NAME';

import { setDataChat } from '../../redux/slices/chat';

import useAi from '../../hooks/useAi';

const OpenAI = () => {
    const [message, setMessage] = React.useState("");
    const contentRef = React.useRef(null);

    const {isLoading, error, sendMessage, regenerate} = useAi();
    const {dialog} = useSelector(state => state.chat);
    const dispatch = useDispatch();

    const sendMessageHandler = (e) => {
        e.preventDefault();

        if(!message || isLoading){
            return;
        }

        sendMessage(message);
        setMessage("");
    }

    const regenerateHandler = (e) => {
        e.preventDefault();

        if(isLoading){
            return;
        }

        regenerate();
    }

    React.useEffect(() => {
        if(error){
            dispatch(setDataChat());
            alert("An error occurred, please try later");
        }
    }, [error]);

    React.useEffect(() => {
        if (contentRef.current !== null) {
            contentRef.current.scrollTop = contentRef.current.scrollHeight;
        }
    }, [dialog]);

    React.useEffect(() => {
        document.title = `${NAME.toUpperCase()} AI`;
    }, []);

    return (
        <div className="main" id="chat">
            <img src="/assets/img/bg.png" alt="bg" className="main__bg" />

            <div className="container">
                <div className="main__inner">
                    <div className="main__logo--inner">
                         <p className="main__logo">
                            {NAME} <span>AI</span>
                         </p>

                        <img src="/assets/img/tm.svg" alt="tm" className="main__logo--tm" />
                    </div>

                    <div className="main__content" ref={contentRef}>
                        {dialog.length > 0 ? <div className="main__dialog--content">
                            {dialog.map((data, id) => <div key={id} className="main__dialog--item">
                                <div className={`main__dialog--img--inner${data.role === "assistant" ? " assistant" : ""}`}>
                                    <img src={`/assets/img/${data.role === "assistant" ? "web.svg" : "man.svg"}`} alt="man" className="main__dialog--img" />
                                </div>

                                <p className="main__dialog--text">
                                    <pre>
                                        {data.content.replace(/\`/g, "")}
                                    </pre>
                                </p>
                            </div>)}

                            {isLoading && <div className="main__dialog--item">
                                <div className="main__dialog--img--inner assistant">
                                    <img src="/assets/img/web.svg" alt="web" className="main__dialog--img" />
                                </div>

                                <p className="main__dialog--text">
                                    Print...
                                </p>
                            </div>}
                        </div>
                        : <div className="main__welcome">
                            <p className="main__welcome--title">
                                ENJOY YOUR {NAME} AI
                            </p>
                        </div>}
                    </div>

                    <div className={`main__input--inner${isLoading ? " loading" : ""}`}>
                        <button className="button main__input--clear" onClick={() => setMessage("")}>
                            <img src="/assets/img/clean.svg" alt="clean" className="main__input--clear--icon" />
                        </button>

                        <form action="#" className="main__input--wrapper" onSubmit={sendMessageHandler}>
                            <input type="text" className="input main__input" placeholder={`Ask me anything related to ${NAME}`} value={message} onChange={e => setMessage(e.target.value)} />

                            <img src="/assets/img/message.svg" alt="message" className="main__input--message" />

                            <button className="button main__input--send">
                                <img src="/assets/img/send.svg" alt="send" className="main__input--send--icon" />
                            </button>
                        </form>

                        <button className={`button regenerate${!isLoading && dialog.length !== 0 && !error ? " active" : ""}`} onClick={regenerateHandler}>
                            <img src="/assets/img/refresh.svg" alt="refresh" className="regenerate__icon" />

                            Regenerate
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OpenAI;