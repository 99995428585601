import React from 'react';

import "./App.css";

import Main from './pages/Main';

const App = () => {
    return (
        <Main />
    )
}

export default App;
