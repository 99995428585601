import React, { useState, useEffect } from "react";

import "./index.css";

const CustomCursor = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [hovered, setHovered] = useState(false);

    useEffect(() => {
        const handleMouseMove = (event) => {
            setPosition({ x: event.clientX - 19, y: event.clientY - 19 });
        };

        document.addEventListener("mousemove", handleMouseMove);

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    useEffect(() => {
        const elementsWithPointerCursor = document.querySelectorAll("a");

        const handleElementMouseEnter = () => {
            setHovered(true);
        };

        const handleElementMouseLeave = () => {
            setHovered(false);
        };

        elementsWithPointerCursor.forEach((element) => {
            element.addEventListener("mouseenter", handleElementMouseEnter);
            element.addEventListener("mouseleave", handleElementMouseLeave);
        });

        return () => {
            elementsWithPointerCursor.forEach((element) => {
                element.removeEventListener(
                    "mouseenter",
                    handleElementMouseEnter
                );
                element.removeEventListener(
                    "mouseleave",
                    handleElementMouseLeave
                );
            });
        };
    }, []);

    return (
        <div
            className={`custom__cursor${hovered ? " hovered" : ""}`}
            style={{ left: position.x, top: position.y }}
        ></div>
    );
};

export default CustomCursor;
