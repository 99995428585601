import React from 'react';
import $ from 'jquery';

import FaqItem from '../components/FaqItem';
import OpenAI from '../components/OpenAI';
import CustomCursor from '../components/CustomCursor';

const Main = () => {
    const [menu, setMenu] = React.useState(false);
    // const [scale, setScale] = React.useState(0.8);

    // React.useEffect(() => {
    //     const handleScroll = () => {
    //         const currentPosition = window.pageYOffset;

    //         // Определите позицию элемента, до которого нужно долистать
    //         const targetElement = document.getElementById('targetElement');
    //         const targetElementPosition = targetElement.offsetTop;

    //         // Измените значение scale в зависимости от прокрутки страницы
    //         const maxScroll = targetElementPosition;
    //         const scrollRange = Math.min(maxScroll, currentPosition);
    //         const scrollPercentage = scrollRange / maxScroll;
    //         const scaleRange = 1 - 0.8;
    //         const newScale = 0.8 + scrollPercentage * scaleRange;
    //         setScale(newScale);
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    React.useEffect(() => {
        $(".go").on("click", function(e){
            e.preventDefault();
            let point = $(this).attr("data-point");
            setMenu(false);
            
            $('body,html').animate({scrollTop: $("#"+point).offset().top}, 500);
        });
    }, []);

    return (
        <>
            <CustomCursor />

            <img src="/assets/img/bg-page.png" alt="bg" className="bg" />

            <header className="header">
                <div className={`header__menu${menu ? " active" : ""}`}>
                    <div className="container">
                        <div className="header__menu--inner">
                            <nav className="header__menu--nav">
                                <a href="#" className="header__menu--link go" data-point="home">Home</a>
                                <a href="#" className="header__menu--link go" data-point="about">About</a>
                                <a href="#" className="header__menu--link go" data-point="chat">Chatbot</a>
                                <a href="#" className="header__menu--link go" data-point="faq">Faq</a>
                            </nav>

                            <div className="launch">
                                Launching soon
                            </div>

                            <p className="copy">
                                &copy; 2023 BITCOIN AI
                            </p>

                            <div className="header__social mobile">
                                <a href="https://twitter.com/Bitcoin_AI_io" target="_blank" className="header__social--link">
                                    <img src="/assets/img/twitter.svg" alt="twitter" className="header__social--icon" />
                                </a>

                                <a href="https://t.me/bitcoinai_io" target="_blank" className="header__social--link">
                                    <img src="/assets/img/telegram.svg" alt="telegram" className="header__social--icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header__top">
                    <div className="container">
                        <div className="header__top--inner">
                            <div className="header__top--wrap">
                                <a href="/" className="header__top--logo--inner">
                                    <img src="/assets/img/logo.svg" alt="logo" className="header__top--logo" />

                                    <p className="header__top--logo--text">
                                        Bitcoin AI
                                    </p>
                                </a>
                            </div>

                            <div className="header__social">
                                <a href="https://twitter.com/Bitcoin_AI_io" target="_blank" className="header__social--link">
                                    <img src="/assets/img/twitter.svg" alt="twitter" className="header__social--icon" />
                                </a>

                                <a href="https://t.me/bitcoinai_io" target="_blank" className="header__social--link">
                                    <img src="/assets/img/telegram.svg" alt="telegram" className="header__social--icon" />
                                </a>
                            </div>

                            <div className="header__menu--button" onClick={() => setMenu(prev => !prev)}>
                                MENU
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header__bottom">
                    <div className="container">
                        <div className="header__bottom--inner">
                            <a href="/" className="header__logo--inner">
                                <img src="/assets/img/logo.svg" alt="logo" className="header__logo" />

                                <p className="header__logo--text">
                                    Bitcoin AI
                                </p>
                            </a>

                            <nav className="header__nav">
                                <a href="#" className="header__link go" data-point="home">Home</a>
                                <a href="#" className="header__link go" data-point="about">About</a>
                                <a href="#" className="header__link go" data-point="chat">CHATBOT</a>
                                <a href="#" className="header__link go" data-point="faq">Faq</a>
                            </nav>

                            <div className="launch">
                                Launching soon
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <section className="near" id="home">
                <div className="screen">
                    <div className="container">
                        <div className="screen__inner">
                            <div className="screen__title--inner">
                                <div className="screen__title--row">
                                    <p className="screen__title"><span>Bitcoin</span> AI is a</p>
                                </div>

                                <div className="screen__title--row">
                                    <p className="screen__title">Decentralized</p>
                                </div>

                                <div className="screen__title--row">
                                    <p className="screen__title">BTC ordinal Helper</p>
                                </div>
                            </div>

                            <div className="screen__wrapper">
                                <div className="way">
                                    <p className="way__text">
                                        001
                                    </p>

                                    <p className="way__text">
                                        HOME
                                    </p>
                                </div>

                                <div className="screen__text--inner">
                                    <p className="screen__text">
                                        Bitcoin AI is the most secure, transparent and decentralized chat helper, created and inscripted by our very own Bitcoin inscribed ordinal. Our helper is based on BTC, crypto&rsquo;s most influential coin, and will expand into a suite of helpers, assistants and decentralized tools.
                                    </p>

                                    <a href="#" className="screen__link disabled">LAUNCHING SOON</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="video" id="targetElement">
                    <div className="video__inner" style={{
                        transform: `scale3d(${scale}, ${scale}, 1)`
                    }}>
                        <img src="/assets/img/preview.jpg" alt="preview" className="video__preview" />
                    </div>
                </div> */}
            </section>

            <section className="partners">
                <div className="partners__content">
                    <div className="partner__item">
                        <img src="/assets/img/part1.svg" alt="part" className="partner__item--img" />
                    </div>

                    <div className="partner__item">
                        <img src="/assets/img/part2.svg" alt="part" className="partner__item--img" />
                    </div>

                    <div className="partner__item">
                        <img src="/assets/img/part3.svg" alt="part" className="partner__item--img" />
                    </div>

                    <div className="partner__item">
                        <img src="/assets/img/part4.svg" alt="part" className="partner__item--img" />
                    </div>

                    <div className="partner__item">
                        <img src="/assets/img/part5.svg" alt="part" className="partner__item--img" />
                    </div>

                    <div className="partner__item">
                        <img src="/assets/img/part6.svg" alt="part" className="partner__item--img" />
                    </div>
                </div>

                <div className="partners__content">
                    <div className="partner__item">
                        <img src="/assets/img/part1.svg" alt="part" className="partner__item--img" />
                    </div>

                    <div className="partner__item">
                        <img src="/assets/img/part2.svg" alt="part" className="partner__item--img" />
                    </div>

                    <div className="partner__item">
                        <img src="/assets/img/part3.svg" alt="part" className="partner__item--img" />
                    </div>

                    <div className="partner__item">
                        <img src="/assets/img/part4.svg" alt="part" className="partner__item--img" />
                    </div>

                    <div className="partner__item">
                        <img src="/assets/img/part5.svg" alt="part" className="partner__item--img" />
                    </div>

                    <div className="partner__item">
                        <img src="/assets/img/part6.svg" alt="part" className="partner__item--img" />
                    </div>
                </div>
            </section>

            <section className="stats" id="about">
                <div className="container">
                    <div className="stats__inner">
                        <div className="stats__text--inner">
                            <p className="stats__title">
                                WE SUCCESSFULLY RAISED $20,000 USD
                            </p>

                            <p className="stats__text">
                                BTCAI raised $20,000 to help support development & marketing. Our strong, well-connected & ambitious venture have strong utility, strong meme-eability, and a driven community that is the foundation of the evolution of the project.
                            </p>

                            <a href="#" className="stats__link">LAUNCHING SOON</a>
                        </div>

                        <div className="stats__items">
                            <div className="stats__item">
                                <p className="stats__title">
                                    1
                                </p>

                                <p className="stats__item--subtitle">
                                    Tools, memes and BTC
                                </p>

                                <p className="stats__text">
                                    The core of BITCOIN AI expands beyond our ecosystem of tools, chatters. Grounded by a symbol, expanded by innovation.
                                </p>
                            </div>

                            <div className="stats__item">
                                <p className="stats__title">
                                    100%
                                </p>

                                <p className="stats__item--subtitle">
                                    Fun and memes
                                </p>

                                <p className="stats__text">
                                    Our movement is meant to be fun and entertaining for our community with the most well-known names in the space. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <OpenAI />

            <section className="title__wrapper">
                <div className="container">
                    <div className="title__inner">
                        <p className="title__title">
                            Your personal expert in anything
                        </p>

                        <p className="title__text">
                            Experience the smartest TG chatbot, 100% free to use for all.
                        </p>

                        <a href="https://t.me/bitcoin_ai_chatbot" className="bitcoin__link" target="_blank">Add to Telegram</a>
                    </div>
                </div>
            </section>

            <section className="bitcoin">
                <div className="container">
                    <div className="bitcoin__inner">
                        <img src="/assets/img/bitcoin-img.png" alt="bitcoin" className="bitcoin__img" />

                        <p className="bitcoin__title">
                            <span>BITCOIN</span> AI
                        </p>

                        <p className="bitcoin__text">
                            Fueling memes, originality and tools
                        </p>

                        <a href="#" className="bitcoin__link disabled">
                            LAUNCHING SOON
                        </a>
                    </div>
                </div>
            </section>

            <section className="faq" id="faq">
                <div className="container">
                    <div className="faq__inner">
                        <div className="faq__content">
                            <div className="faq__content--text--inner">
                                <p className="faq__content--title">
                                    GOT QUESTIONS? WE GOT ANSWERS
                                </p>

                                <p className="faq__content--text">
                                    Information overload in Web 3.0 is real... Fortunately, we&rsquo;re here to help & grow with you.
                                </p>
                            </div>

                            <div className="faq__content--items">
                                <FaqItem
                                    title="WHAT IS BITCOIN AI?"
                                    text="BITCOIN AI is a decentralized suite of helpers, inspired by the most iconic and transformative symbol of them all, Bitcoin. The project is engraved in a Bitcoin inscribed ordinal."
                                />

                                <FaqItem
                                    title="IS THERE ANY PRIVATE OR SEED SALE?"
                                    text="No, there has only been a presale, no other sales have occurred."
                                />

                                <FaqItem
                                    title="IS IT SAFE TO INVEST IN?"
                                    text="BITCOIN AI is vetted, audited and will be launched on one of the world’s largest Exchanges."
                                />

                                <FaqItem
                                    title="WHERE CAN I BUY IT?"
                                    text="Launch information will be released shortly."
                                />
                            </div>
                        </div>

                        <div className="way">
                            <p className="way__text">
                                010
                            </p>

                            <p className="way__text">
                                COOL AI / EASy Bitcoin
                            </p>
                        </div>

                        <div className="faq__message">
                            <p className="faq__message--title">
                                BECOME AN AMBASSADOR
                            </p>

                            <a href="#" className="faq__message--link">CONTACT</a>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="footer">
                <div className="footer__top">
                    <div className="container">
                        <div className="footer__top--content">
                            <div className="footer__top--wrapper">
                                <div className="footer__top--logo--inner">
                                    <img src="/assets/img/logo.svg" alt="logo" className="footer__top--logo" />

                                    <p className="footer__top--logo--text">
                                        Bitcoin AI
                                    </p>
                                </div>

                                <p className="footer__top--title">
                                    INSPIRED BY BITCOIN AND FUELED BY THE NEXT GENERATION OF TOOLS AND MEMES
                                </p>
                            </div>

                            <div className="footer__top--wrapper">
                                <div className="footer__top--form--inner">
                                    <p className="footer__top--form--title">
                                        Stay in the know
                                    </p>

                                    <div className="footer__top--form">
                                        <input type="text" className="input footer__top--form--input" placeholder="Your email here" />

                                        <button className="button footer__top--form--button">
                                            <img src="/assets/img/arrow-right.svg" alt="arrow" className="footer__top--form--icon" />
                                        </button>
                                    </div>

                                    <div className="footer__top--socials">
                                        <p className="footer__top--socials--title">
                                            Socials
                                        </p>

                                        <a href="https://twitter.com/Bitcoin_AI_io" target="_blank" className="footer__top--social--link">
                                            <img src="/assets/img/twitter-white.svg" alt="twitter" className="footer__top--social--icon" />
                                        </a>

                                        <a href="https://t.me/bitcoinai_io" target="_blank" className="footer__top--social--link">
                                            <img src="/assets/img/telegram-white.svg" alt="telegram" className="footer__top--social--icon" />
                                        </a>
                                    </div>
                                </div>

                                <nav className="footer__top--menu">
                                    <a href="#" className="footer__top--link go" data-point="home">Home</a>
                                    <a href="#" className="footer__top--link go" data-point="about">About</a>
                                    <a href="#" className="footer__top--link go" data-point="chat">CHATBOT</a>
                                    <a href="#" className="footer__top--link go" data-point="faq">Faq</a>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer__bottom">
                    <div className="container">
                        <div className="footer__bottom--content">
                            <p className="footer__text">
                                Coming soon on Exchanges...
                            </p>
                            
                            <p className="footer__text">
                                &copy; 2023 BITCOIN AI
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Main;